import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../UserAvatar";
import Follow from "../Follow";

const UserSuggestionItem = ({ user }) => {
  return (
    <div className="user-item-container">
      <UserAvatar
        avatarUrl={user.avatar}
        username={user.username}
        userLevel={user.user_level}
      />
      <div className="user-info">
        <div className="user-name">
          <Link to={`/users/${user.id}`}>{user.username}</Link>
        </div>
        <div className="user-stats">
          <div className="stat-item">
            <span className="stat-number">{user.followers_count}</span>{" "}
            Followers
          </div>
        </div>
      </div>
      <Follow
        followed={user.is_followed}
        userId={user.id}
        className="user-action-button"
      />
    </div>
  );
};

export default UserSuggestionItem;
