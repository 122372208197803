import React, { useEffect, useState, useContext } from "react";
import { AxiosContext } from "../../App";
import Loader from "../Loader";
import UserSuggestionItem from "./UserSuggestionItem";

const UserSuggestions = () => {
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosInstance = useContext(AxiosContext);

  useEffect(() => {
    const fetchUserSuggestions = async () => {
      try {
        const response = await axiosInstance.get("/user-suggestions/");
        setSuggestedUsers(response.data);
      } catch (error) {
        console.error("Error fetching user suggestions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSuggestions();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // If there are no suggested users, don't render anything
  if (!loading && suggestedUsers.length === 0) {
    return null;
  }

  return (
    <div className="user-suggestions-container">
      <div className="user-suggestions-heading">Who to follow</div>
      <div className="user-suggestions-list">
        <div className="suggested-users">
          {suggestedUsers.map((user) => (
            <UserSuggestionItem key={user.id} user={user} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserSuggestions;
