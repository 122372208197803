// SignInForm.js

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import handleSignIn from "../authUtils";
import { useUserContext } from "../components/UserContext";
import { useNotifications } from "../components/NotificationsContext";
import logo from "../assets/icon.webp";

const SignInForm = ({ isAuthenticated, handleAuthentication }) => {
  const {
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
  } = useUserContext();

  const { setNotifications } = useNotifications();
  const navigate = useNavigate();

  // State for tracking if the image is loaded
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Navigate to main if user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Define onSubmit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSignIn(
        username,
        password,
        handleAuthentication,
        setUserId,
        setUserName,
        setUserAvatar,
        setUserFollows,
        setUserFollowers,
        navigate,
        setNotifications
      );
    } catch (error) {
      setError(error.message);
    }
  };

  // Handler for image load
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="signin-container">
      <div className="info-section">
        <img
          src={logo}
          alt="Cavegram Logo"
          className={`logo ${isImageLoaded ? "loaded" : ""}`}
          onLoad={handleImageLoad}
        />
        <h1 className="service-name">
          Cavegram
          <span className="pre-alpha">pre-alpha</span>
        </h1>
        <h2 className="slogan">
          Your <span className="primal-gradient">primal</span> way to connect
        </h2>
        <p className="description">
          Cavegram is a social platform where everything is a hand-drawn image.
        </p>
      </div>
      <div className="form-section">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="signin-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="signin-button">Sign In</button>
        </form>
        <Link to="/signup" className="signin-form signup-button">Create Account</Link>
      </div>
    </div>
  );
};

export default SignInForm;
