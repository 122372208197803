import React, { useState, useEffect, useRef, createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Loader from "./components/Loader";
import Mainpage from "./pages/Mainpage";
import Feed from "./pages/Feed";
import Chats from "./pages/Chats";
import MoodJournal from "./pages/MoodJournal";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import FollowsList from "./components/FollowsList";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import UserSettings from "./pages/UserSettings";
import Challenges from "./pages/Challenges";
import DoodleEditor from "./components/DoodleEditor/DoodleEditor";
import { useDoodleContext } from "./components/DoodleContext";
import { useUserContext } from "./components/UserContext";
import createAxiosInstance from "./axiosInstance";
import SideMenu from "./components/SideMenu";
import LowerNav from "./components/LowerNav";
import { NotificationsProvider } from "./components/NotificationsContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, toast } from "react-toastify";
import MoodPrompt from "./components/MoodPrompt/MoodPrompt";
import "react-toastify/dist/ReactToastify.css";
import { initializeAnalytics } from "./analytics/Analytics";
import TrackPageView from "./analytics/TrackPageView";
import UserProgressScreen from "./components/UserProgressScreen/UserProgressScreen";
import ReferralTracker from "./components/ReferralTracker";

// Create a context for the axios instance
export const AxiosContext = createContext(null);

function App() {
  const { onDoodleSubmit } = useDoodleContext();

  // Set authenticated status to null to indicate that the authentication status hasn't been checked yet
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // States for progression counters
  const [userScore, setUserScore] = useState(0);
  const [userLevel, setUserLevel] = useState(0);
  const [levelCompletionPercent, setLevelCompletionPercent] = useState(0);

  // State for item spawn message
  const [spawnMessage, setSpawnMessage] = useState(null);

  // State for mood journalling prompt
  const [showMoodPrompt, setShowMoodPrompt] = useState(false);

  const {
    userId,
    userName,
    userAvatar,
    userFollows,
    userFollowers,
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
    follows,
    followers,
  } = useUserContext();

  // Update values when notifications are fetched
  useEffect(() => {
    if (follows) {
      setUserFollows(follows);
    }
    if (followers) {
      setUserFollowers(followers);
    }
  }, [follows, followers]);

  // State for opening and closing side menu
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Set states for Doodle Editor
  const [isDoodleEditorOpen, setIsDoodleEditorOpen] = useState(false);
  const [editorIntention, setEditorIntention] = useState("postOnMyWall");
  const [existingDoodleId, setExistingDoodleId] = useState(null);
  const [editorContext, setEditorContext] = useState(null);

  // Set overlay for 'follows'/'followers' interstitial
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [selectedUsersList, setSelectedUsersList] = useState(null); // 'follows' or 'followers'
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance
        .post("/check-auth/") // Perform an initial authentication check
        .then((response) => {
          setIsAuthenticated(true);
          setUserId(response.data.id); // Store user ID in the state
          setUserName(response.data.username); // Store user name in the state
          setUserAvatar(response.data.avatar); // Store user avatar in the state
          setUserFollows(response.data.follows); // Store follows count
          setUserFollowers(response.data.followers); // Store followers count
        })
        .catch(() => {
          setIsAuthenticated(false); // Set authentication state to false if authentication fails
          setUserId(null); // Clear user ID if authentication fails
        });
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // Function to handle user authentication status changes
  const handleAuthentication = (loggedIn, userId, userName) => {
    setIsAuthenticated(loggedIn);
    setUserId(userId);
    setUserName(userName);
  };

  // Create Axios instance for API calls inside App.js and in nested components using Context API
  const axiosInstance = createAxiosInstance(
    setIsAuthenticated,
    setUserScore,
    setUserLevel,
    setLevelCompletionPercent,
    setSpawnMessage,
    setShowMoodPrompt
  );

// Function for handling press of the Open editor button
const handleOpenDoodleEditor = (intention, context = null, existingDoodleId = null) => {
  setIsDoodleEditorOpen(true);
  setEditorIntention(intention);
  setEditorContext(context);
  setExistingDoodleId(existingDoodleId);
};

  // Function for handling press of the Close editor button
  const handleCloseDoodleEditor = () => {
    setIsDoodleEditorOpen(false);
  };

  // Handle events related to side menu
  const toggleSideMenu = (event) => {
    // Stop event propagation to prevent the click event from reaching the document
    event.stopPropagation();
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const handleClickOutsideSideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsSideMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isSideMenuOpen) {
      document.addEventListener("click", handleClickOutsideSideMenu);
    } else {
      document.removeEventListener("click", handleClickOutsideSideMenu);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideSideMenu);
    };
  }, [isSideMenuOpen]);

  // Manage follows/followers overlay open and close
  const openOverlay = (listType) => {
    setSelectedUsersList(listType);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setSelectedUsersList(null);
  };

  const handleFollowChange = (newIsFollowed, userId = null) => {
    // Update the follows count on the logged-in user
    setUserFollows((prevUserFollows) =>
      newIsFollowed ? prevUserFollows + 1 : prevUserFollows - 1
    );
    if (userId) {
      // Update the state for the specific user in the overlay
      setUsersList((prevUsersList) => {
        return prevUsersList.map((user) =>
          user.id === userId ? { ...user, is_followed: newIsFollowed } : user
        );
      });
    }
  };

  useEffect(() => {
    if (spawnMessage) {
      toast.info(spawnMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => setSpawnMessage(null), // Clear the message after it's closed
      });
    }
  }, [spawnMessage]);

  const handleCloseMoodPrompt = () => {
    setShowMoodPrompt(false);
  };

  // State to control overlay visibility
  const [showProgressOverlay, setShowProgressOverlay] = useState(false);

  // Function to handle overlay toggle
  const toggleProgressOverlay = () => {
    setShowProgressOverlay(!showProgressOverlay);
  };

  return (
    <div className="common-structure">
      <Router>
        <ReferralTracker />
        <TrackPageView />
        <AxiosContext.Provider value={axiosInstance}>
          <NotificationsProvider
            isAuthenticated={isAuthenticated}
            setUserScore={setUserScore}
            setUserLevel={setUserLevel}
            setLevelCompletionPercent={setLevelCompletionPercent}
          >
            <Header
              setIsAuthenticated={setIsAuthenticated}
              isAuthenticated={isAuthenticated}
              handleOpenDoodleEditor={handleOpenDoodleEditor}
              toggleSideMenu={toggleSideMenu}
            />
            {isDoodleEditorOpen && (
              <DoodleEditor
                onClose={handleCloseDoodleEditor}
                onDoodleSubmit={onDoodleSubmit}
                editorIntention={editorIntention}
                editorContext={editorContext}
                doodleToEditId={existingDoodleId}
              />
            )}
            {isAuthenticated === null ? (
              <Loader /> // Render a loader component while authentication check is in progress
            ) : (
              <Routes>
                <Route
                  path="/signin"
                  element={
                    <SignIn
                      isAuthenticated={isAuthenticated}
                      handleAuthentication={handleAuthentication}
                    />
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <SignUp
                      isAuthenticated={isAuthenticated}
                      handleAuthentication={handleAuthentication}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Routes>
                        <Route
                          path="/:pathUsername"
                          element={
                            <Mainpage
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <Feed
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                              onFollowChange={handleFollowChange}
                            />
                          }
                        />
                        <Route
                          path="/chats"
                          element={
                            <Chats
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route path="/mood-journal" element={<MoodJournal />} />
                        <Route
                          path="/profile/:pathUsername"
                          element={
                            <Profile
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route
                          path="/profile"
                          element={
                            <Profile
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route
                          path="/notifications"
                          element={
                            <Notifications
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route
                          path="/settings"
                          element={
                            <UserSettings
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                        <Route
                          path="/challenges"
                          element={
                            <Challenges
                              userId={userId}
                              userName={userName}
                              handleOpenDoodleEditor={handleOpenDoodleEditor}
                            />
                          }
                        />
                      </Routes>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            )}
            {/* Menu screen */}
            {isAuthenticated && (
              <SideMenu
                isSideMenuOpen={isSideMenuOpen}
                toggleSideMenu={toggleSideMenu}
                menuRef={menuRef}
                userName={userName}
                userAvatar={userAvatar}
                userFollows={userFollows}
                userFollowers={userFollowers}
                openOverlay={openOverlay}
                setIsAuthenticated={setIsAuthenticated}
                userScore={userScore}
                userLevel={userLevel}
                levelCompletionPercent={levelCompletionPercent}
                toggleProgressOverlay={toggleProgressOverlay}
              />
            )}
            {isAuthenticated && <LowerNav />}
            {/* Render follows/followers overlay if open */}
            {isOverlayOpen && (
              <FollowsList
                listType={selectedUsersList}
                onClose={closeOverlay}
                username={userName}
                loggedInUserId={userId}
                onFollowChange={handleFollowChange}
                usersList={usersList}
                setUsersList={setUsersList}
              />
            )}
            {showMoodPrompt && (
              <MoodPrompt
                onClose={handleCloseMoodPrompt}
                handleOpenDoodleEditor={handleOpenDoodleEditor}
              />
            )}
            {/* Render the overlay component conditionally */}
            {showProgressOverlay && (
              <UserProgressScreen
                userLevel={userLevel}
                userScore={userScore}
                levelCompletionPercent={levelCompletionPercent}
                onClose={toggleProgressOverlay}
                userName={userName}
              />
            )}
          </NotificationsProvider>
        </AxiosContext.Provider>
      </Router>
      {isAuthenticated && (
        <div
          className="plus-icon"
          onClick={() => handleOpenDoodleEditor("postOnMyWall")}
        >
          +
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
